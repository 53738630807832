export default class Diagnostic {
    /**
     * @param formSelector { string }
     * @param matrix { Matrix[] }
     */
    constructor(matrix) {
        this.#data = matrix;
    }

    /**
     * @type Matrix[]
     */
    #data;
    #redirectPage = '/dist/result/result.html';

    diagnose = (form) => {
        const {age, skin, holiday, children} = Object.fromEntries(new FormData(form).entries());
        const products = this.getResultProducts(age, skin, holiday, children);
        const redirectUrl = this.makeRedirectUrl(products);

        location.href = redirectUrl.href;
    }

    /**
     * Select products based on user responses
     * @param age { string }
     * @param skin { string }
     * @param holiday { string }
     * @param children { string }
     * @return { ProductSet }
     */
    getResultProducts = (age, skin, holiday, children) => {
        return this.#data.find((product) => {
            return product.age === age && product.skin === skin && product.holiday === holiday && product.children === children;
        }).products;
    }

    /**
     * Generates a redirection page
     * @param products { ProductSet }
     * @return {URL}
     */
    makeRedirectUrl = (products) => {
        const redirectPage = new URL(this.#redirectPage, location.href);
        console.log(redirectPage);

        for (const key in products) {
            redirectPage.searchParams.set(key, products[key] ? products[key] : '');
        }

        return redirectPage;
    }
}
