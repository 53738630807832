export default class PageProgressController {
    /**
     * @param fragmentSelector { string }
     * @param stepSelector { string }
     * @param imageSelector { string }
     */
    constructor(fragmentSelector, stepSelector, imageSelector) {
        this.#fragments = document.querySelectorAll(fragmentSelector);
        this.#progressElements = document.querySelectorAll(stepSelector);
        this.#images = document.querySelectorAll(imageSelector);

        if (!this.#fragments) {
            throw Error(`No elements found by selector '${fragmentSelector}'`);
        }

        if (!this.#progressElements) {
            throw Error(`No elements found by selector '${stepSelector}'`);
        }

        if (this.#fragments.length !== this.#progressElements.length &&
            this.#fragments.length !== this.#images.length) {
            throw Error('Incorrect stage number');
        }

        this.#countStages = this.#fragments.length;
        this.#hideAllFragments();
        this.#hideAllImages();
        this.goForward();
    }

    /**
     * @type  NodeListOf<any>
     */
    #fragments;
    #progressElements;
    #images;
    #currentStage = -1;
    #countStages;
    #classes = {
        stepActiveClass: 'step_active',
        fragmentHideRight: 'diagnostic-step_hide-right',
        fragmentHideLeft: 'diagnostic-step_hide-left',
        imageHide: 'diagnostic__image_hide',
    }

    goForward = () => {
        if (!this.canSwitchForward()) {
            return;
        }

        if (this.#currentStage >= 0) {
            this.#hideFormFragment(this.#fragments[this.#currentStage], this.#classes.fragmentHideLeft);
            this.#hideImage(this.#images[this.#currentStage]);
        }

        this.#currentStage++;
        this.#showFormFragment(this.#fragments[this.#currentStage]);
        this.#increaseProgress();
        this.#showImage(this.#images[this.#currentStage]);
    }

    goBackward = () => {
        if (!this.canSwitchBackward()) {
            return;
        }

        this.#hideFormFragment(this.#fragments[this.#currentStage], this.#classes.fragmentHideRight);
        this.#decreaseProgress();
        this.#hideImage(this.#images[this.#currentStage]);
        this.#currentStage--;
        this.#showFormFragment(this.#fragments[this.#currentStage]);
        this.#showImage(this.#images[this.#currentStage]);
    }

    canSwitchForward = () => {
        return this.#currentStage + 1 < this.#countStages;
    }

    canSwitchBackward = () => {
        return this.#currentStage - 1 >= 0;
    }

    #hideAllFragments() {
        for (let i = 0; i < this.#fragments.length; i++) {
            this.#hideFormFragment(this.#fragments[i], this.#classes.fragmentHideRight);
        }
    }

    #hideAllImages() {
        for (let i = 0; i < this.#images.length; i++) {
            this.#hideImage(this.#images[i]);
        }
    }

    #hideImage(element) {
        element.classList.add(this.#classes.imageHide);
    }

    #showImage(element) {
        element.classList.remove(this.#classes.imageHide);
    }

    /**
     * @param element { HTMLElement }
     * @param className { string }
     */
    #hideFormFragment(element, className) {
        element.classList.add(className);
    }

    /**
     * @param element { HTMLElement }
     */
    #showFormFragment(element) {
        if (element.classList.contains(this.#classes.fragmentHideLeft)) {
            element.classList.remove(this.#classes.fragmentHideLeft);
        } else if (element.classList.contains(this.#classes.fragmentHideRight)) {
            element.classList.remove(this.#classes.fragmentHideRight);
        }
    }

    #increaseProgress = () => {
        this.#progressElements[this.#currentStage].classList.add(this.#classes.stepActiveClass);
    }

    #decreaseProgress = () => {
        this.#progressElements[this.#currentStage].classList.remove(this.#classes.stepActiveClass);
    }
}
