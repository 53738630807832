/**
 * @typedef ProductSet
 * @property faceLrp { number }
 * @property faceVichy { number }
 * @property bodyLrp { number }
 * @property bodyVichy { number }
 * @property kidsLrp { number | null }
 * @property kidsVichy { number | null }
 */

/**
 * @typedef Matrix
 * @property age {string}
 * @property skin {string}
 * @property holiday {string}
 * @property children {string}
 * @property resultProducts { ProductSet[] }
 */

/**
 * @type Matrix[]
 */
const data = [
    {
        age: '18-25',
        skin: 'normal',
        holiday: 'city',
        children: 'yes',
        products: {
            faceLrp: 30162662,
            faceVichy: 3337875762298,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '18-25',
        skin: 'oily',
        holiday: 'city',
        children: 'yes',
        products: {
            faceLrp: 3337875797467,
            faceVichy: 3337875837149,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '18-25',
        skin: 'dry',
        holiday: 'city',
        children: 'yes',
        products: {
            faceLrp: 3337875588560,
            faceVichy: 3337875762298,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '18-25',
        skin: 'combination',
        holiday: 'city',
        children: 'yes',
        products: {
            faceLrp: 3337875797467,
            faceVichy: 3337875762298,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '26-40',
        skin: 'normal',
        holiday: 'city',
        children: 'yes',
        products: {
            faceLrp: 3337875761031,
            faceVichy: 3337875762298,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '26-40',
        skin: 'oily',
        holiday: 'city',
        children: 'yes',
        products: {
            faceLrp: 3337875797467,
            faceVichy: 3337875837149,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '26-40',
        skin: 'dry',
        holiday: 'city',
        children: 'yes',
        products: {
            faceLrp: 3337875761031,
            faceVichy: 3337875762298,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '26-40',
        skin: 'combination',
        holiday: 'city',
        children: 'yes',
        products: {
            faceLrp: 3337875797467,
            faceVichy: 3337875762298,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '41+',
        skin: 'normal',
        holiday: 'city',
        children: 'yes',
        products: {
            faceLrp: 3337875761031,
            faceVichy: 3337875762298,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '41+',
        skin: 'oily',
        holiday: 'city',
        children: 'yes',
        products: {
            faceLrp: 3337875797467,
            faceVichy: 3337875837149,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '41+',
        skin: 'dry',
        holiday: 'city',
        children: 'yes',
        products: {
            faceLrp: 3337875761031,
            faceVichy: 3337875762298,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '41+',
        skin: 'combination',
        holiday: 'city',
        children: 'yes',
        products: {
            faceLrp: 3337875797467,
            faceVichy: 3337875762298,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '18-25',
        skin: 'normal',
        holiday: 'sea',
        children: 'yes',
        products: {
            faceLrp: 30162662,
            faceVichy: 3337871323196,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '18-25',
        skin: 'oily',
        holiday: 'sea',
        children: 'yes',
        products: {
            faceLrp: 3337875546430,
            faceVichy: 3337875695176,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '18-25',
        skin: 'dry',
        holiday: 'sea',
        children: 'yes',
        products: {
            faceLrp: 3337875588560,
            faceVichy: 3337871323196,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '18-25',
        skin: 'combination',
        holiday: 'sea',
        children: 'yes',
        products: {
            faceLrp: 3337875546430,
            faceVichy: 3337871323196,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '26-40',
        skin: 'normal',
        holiday: 'sea',
        children: 'yes',
        products: {
            faceLrp: 30162662,
            faceVichy: 3337871323196,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '26-40',
        skin: 'oily',
        holiday: 'sea',
        children: 'yes',
        products: {
            faceLrp: 3337875546430,
            faceVichy: 3337875695176,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '26-40',
        skin: 'dry',
        holiday: 'sea',
        children: 'yes',
        products: {
            faceLrp: 3337875588560,
            faceVichy: 3337871323196,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '26-40',
        skin: 'combination',
        holiday: 'sea',
        children: 'yes',
        products: {
            faceLrp: 3337875546430,
            faceVichy: 3337871323196,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '41+',
        skin: 'normal',
        holiday: 'sea',
        children: 'yes',
        products: {
            faceLrp: 30162662,
            faceVichy: 3337871323196,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '41+',
        skin: 'oily',
        holiday: 'sea',
        children: 'yes',
        products: {
            faceLrp: 3337875546430,
            faceVichy: 3337875695176,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '41+',
        skin: 'dry',
        holiday: 'sea',
        children: 'yes',
        products: {
            faceLrp: 3337875588560,
            faceVichy: 3337871323196,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '41+',
        skin: 'combination',
        holiday: 'sea',
        children: 'yes',
        products: {
            faceLrp: 3337875546430,
            faceVichy: 3337871323196,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: 3337872419904,
            kidsVichy: 3337875558037
        }
    }, {
        age: '18-25',
        skin: 'normal',
        holiday: 'city',
        children: 'no',
        products: {
            faceLrp: 30162662,
            faceVichy: 3337875762298,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '18-25',
        skin: 'oily',
        holiday: 'city',
        children: 'no',
        products: {
            faceLrp: 3337875797467,
            faceVichy: 3337875837149,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '18-25',
        skin: 'dry',
        holiday: 'city',
        children: 'no',
        products: {
            faceLrp: 3337875588560,
            faceVichy: 3337875762298,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '18-25',
        skin: 'combination',
        holiday: 'city',
        children: 'no',
        products: {
            faceLrp: 3337875797467,
            faceVichy: 3337875762298,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '26-40',
        skin: 'normal',
        holiday: 'city',
        children: 'no',
        products: {
            faceLrp: 3337875761031,
            faceVichy: 3337875762298,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '26-40',
        skin: 'oily',
        holiday: 'city',
        children: 'no',
        products: {
            faceLrp: 3337875797467,
            faceVichy: 3337875837149,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '26-40',
        skin: 'dry',
        holiday: 'city',
        children: 'no',
        products: {
            faceLrp: 3337875761031,
            faceVichy: 3337875762298,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '26-40',
        skin: 'combination',
        holiday: 'city',
        children: 'no',
        products: {
            faceLrp: 3337875797467,
            faceVichy: 3337875762298,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '41+',
        skin: 'normal',
        holiday: 'city',
        children: 'no',
        products: {
            faceLrp: 3337875761031,
            faceVichy: 3337875762298,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '41+',
        skin: 'oily',
        holiday: 'city',
        children: 'no',
        products: {
            faceLrp: 3337875797467,
            faceVichy: 3337875837149,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '41+',
        skin: 'dry',
        holiday: 'city',
        children: 'no',
        products: {
            faceLrp: 3337875761031,
            faceVichy: 3337875762298,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '41+',
        skin: 'combination',
        holiday: 'city',
        children: 'no',
        products: {
            faceLrp: 3337875797467,
            faceVichy: 3337875762298,
            bodyLrp: 3337875761116,
            bodyVichy: 3337875585187,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '18-25',
        skin: 'normal',
        holiday: 'sea',
        children: 'no',
        products: {
            faceLrp: 30162662,
            faceVichy: 3337871323196,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '18-25',
        skin: 'oily',
        holiday: 'sea',
        children: 'no',
        products: {
            faceLrp: 3337875546430,
            faceVichy: 3337875695176,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '18-25',
        skin: 'dry',
        holiday: 'sea',
        children: 'no',
        products: {
            faceLrp: 3337875588560,
            faceVichy: 3337871323196,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '18-25',
        skin: 'combination',
        holiday: 'sea',
        children: 'no',
        products: {
            faceLrp: 3337875546430,
            faceVichy: 3337871323196,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '26-40',
        skin: 'normal',
        holiday: 'sea',
        children: 'no',
        products: {
            faceLrp: 30162662,
            faceVichy: 3337871323196,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '26-40',
        skin: 'oily',
        holiday: 'sea',
        children: 'no',
        products: {
            faceLrp: 3337875546430,
            faceVichy: 3337875695176,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '26-40',
        skin: 'dry',
        holiday: 'sea',
        children: 'no',
        products: {
            faceLrp: 3337875588560,
            faceVichy: 3337871323196,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '26-40',
        skin: 'combination',
        holiday: 'sea',
        children: 'no',
        products: {
            faceLrp: 3337875546430,
            faceVichy: 3337871323196,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '41+',
        skin: 'normal',
        holiday: 'sea',
        children: 'no',
        products: {
            faceLrp: 30162662,
            faceVichy: 3337871323196,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '41+',
        skin: 'oily',
        holiday: 'sea',
        children: 'no',
        products: {
            faceLrp: 3337875546430,
            faceVichy: 3337875695176,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '41+',
        skin: 'dry',
        holiday: 'sea',
        children: 'no',
        products: {
            faceLrp: 3337875588560,
            faceVichy: 3337871323196,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: null,
            kidsVichy: null
        }
    }, {
        age: '41+',
        skin: 'combination',
        holiday: 'sea',
        children: 'no',
        products: {
            faceLrp: 3337875546430,
            faceVichy: 3337871323196,
            bodyLrp: 3337875761123,
            bodyVichy: 3337875695152,
            kidsLrp: null,
            kidsVichy: null
        }
    }];

export default data;
