import data from "./src/data";
import Diagnostic from "./src/diagnostic";
import PageProgressController from "./src/page-progress-controller";
import Loader from "./src/loader";

const diagnosticStepsSelector = '.diagnostic-step';
const formPagination = new PageProgressController(diagnosticStepsSelector, '.step', '.diagnostic__image');
const backButton = document.getElementById('back-button');
const defaultBackButtonDisplay = backButton.style.display;
let isBackButtonShown = true;
const form = document.getElementById('diagnostic-form');
const diagnostic = new Diagnostic(data);
const loader = new Loader(() => diagnostic.diagnose(form));

document.querySelectorAll(diagnosticStepsSelector).forEach(diagnosticStep => {
    diagnosticStep.addEventListener('click', (event) => {
        if (event.target instanceof HTMLInputElement && event.target.checked) {
            onAnswerPick();
        }
    });
});

backButton.addEventListener('click', onGoBack);

controlBackButtonDisability();

function onAnswerPick() {
    if (!formPagination.canSwitchForward()) {
        loader.show();
        loader.playAnimation();
    } else {
        formPagination.goForward();
    }

    controlBackButtonDisability();
}

function onGoBack() {
    formPagination.goBackward();
    controlBackButtonDisability();
}

function controlBackButtonDisability() {
    if (!formPagination.canSwitchBackward() && isBackButtonShown) {
        backButton.style.display = 'none';
        isBackButtonShown = false;
    } else if (!isBackButtonShown) {
        backButton.style.display = defaultBackButtonDisplay;
        isBackButtonShown = true;
    }
}
