export default class Loader {
    constructor(onAnimationEnd) {
        this.#loader = document.getElementById('loader');
        this.#loader.addEventListener('animationend', onAnimationEnd);
    }

    #loader
    #modifierClasses = {
        hide: 'loader_hide',
        animated: 'loader_animated'
    }

    show() {
        this.#loader.classList.remove(this.#modifierClasses.hide);
    }

    playAnimation() {
        this.#loader.classList.add(this.#modifierClasses.animated);
    }
}
